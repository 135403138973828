html,
body {
  padding: 0;
  margin: 0;
  background-color: #eeeeee;
  font-family: 'Brandon Text W01 Regular';
  font-weight: 'medium';
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  margin: auto;
}
.Toastify__close-button > svg {
  height: 24px !important;
  width: 24px !important;
}

.toastProgressBar {
  --toastify-color-progress-light: #008024;
}

@font-face {
  font-family: 'Brandon Text W01 Bold';
  src:
    url('../public/fonts/5579079/314148f2-1786-479f-9e0b-e9418b8fee19.woff2') format('woff2'),
    url('../public/fonts/5579079/04c8b798-e77b-4516-98e8-964145344d65.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Bold Italic';
  src:
    url('../public/fonts/5579933/1bf949cc-db2c-44f5-91d6-f90b6a434dca.woff2') format('woff2'),
    url('../public/fonts/5579933/3bac646a-a8d1-4095-9d9a-4d6125c53933.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Black';
  src:
    url('../public/fonts/5579937/59af2f23-b3c8-49fc-9417-0250b65fd399.woff2') format('woff2'),
    url('../public/fonts/5579937/ea7e1e50-53ef-4673-8b08-456be24dd22f.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Black Italic';
  src:
    url('../public/fonts/5579940/43d708cd-11f7-4c51-8dc7-3bfa894f7db9.woff2') format('woff2'),
    url('../public/fonts/5579940/8dcc3ef9-2fe0-49d9-a5d1-305a3fffe699.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Thin';
  src:
    url('../public/fonts/5580062/e7822692-233b-4c9a-b559-a16d55a2bd5c.woff2') format('woff2'),
    url('../public/fonts/5580062/c58df586-ae44-41a7-98ff-0438ac809756.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Thin Italic';
  src:
    url('../public/fonts/5580066/8d407c1f-aef2-484d-adda-2ef0a57ff580.woff2') format('woff2'),
    url('../public/fonts/5580066/861822ff-c969-4291-bd78-94020cdd8006.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Light';
  src:
    url('../public/fonts/5580070/2b8d3660-1a97-4e2b-8637-c27c47ef6e4a.woff2') format('woff2'),
    url('../public/fonts/5580070/f2735d8e-dece-4e49-baeb-a1211c308cd2.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Light Italic';
  src:
    url('../public/fonts/5580074/75e8bf7a-b5e2-4fbb-84ef-2cdc318345c7.woff2') format('woff2'),
    url('../public/fonts/5580074/57664b3f-fe4e-4041-8e68-a94f9a646f2e.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Regular';
  src:
    url('../public/fonts/5580078/03bbca7e-869a-4f4f-a010-39e78be0b5df.woff2') format('woff2'),
    url('../public/fonts/5580078/b3634ffb-e593-4817-9d09-eaf133a014d0.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Regular Italic';
  src:
    url('../public/fonts/5580083/ca2115b4-272c-49bc-bb7c-4b71645943de.woff2') format('woff2'),
    url('../public/fonts/5580083/31f66157-e856-495d-b92b-f7d471a2eafa.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Medium';
  src:
    url('../public/fonts/5580089/12cd8b0d-0f5c-4ec2-b2c7-9b30a8d06ded.woff2') format('woff2'),
    url('../public/fonts/5580089/008df1be-c6b5-4688-a068-6729f08a0b08.woff') format('woff');
}
@font-face {
  font-family: 'Brandon Text W01 Medium Italic';
  src:
    url('../public/fonts/5580093/82cb571f-2a61-46e6-b29e-6de5d83abb29.woff2') format('woff2'),
    url('../public/fonts/5580093/51bfc679-9574-480d-a2b7-2bc382e2070e.woff') format('woff');
}
